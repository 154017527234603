import React, {useEffect, useContext, useState, useRef} from 'react';
import {useIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import {getSectionsLesson} from '../../../store/actions/lessonActions.js';
import {GlobalLoaderContext} from '../../../globalContext/globalLoader/globalLoaderProvider';
import {useParams, useLocation} from 'react-router-dom';
import LessonPath from '../../../components/LessonPath/LessonPath.js';
import CustomModalStudent from '../../../components/CustomModalStudent/CustomModalStudent.js';
import StudentViewLessonContent from '../StudentViewLessonContent/StudentViewLessonContent.js';
import LessonContentHeader from '../StudentViewLessonContent/LessonContentHeader.js';
import LessonContentActionButtons from '../StudentViewLessonContent/LessonContentActionButtons.js';
import {showToast} from '../../../components/Toast/Toast.js';
import {getLocalizedMessage} from '../../../utilityFunction/helper.js';
import './StudentLesson.scss';
import HorizontalScrollButtons from '../../../components/HorizontalScrollButtons/HorizontalScrollButtons.js';

function StudentLesson() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [_, loaderDispatch] = useContext(GlobalLoaderContext);
  const [showLessonContentModal, setShowLessonContentModal] = useState(false);
  const [currentLesson, setCurrentLesson] = useState(null);
  const [myLessonInformation, setMyLessonInformation] = useState(null);
  const {id, section} = useParams();
  const location = useLocation();
  const {slug, isNotificationRedirect, notificationLesson} =
    location.state || {};
  const elementRef = useRef(null);
  const [arrowDisable, setArrowDisable] = useState(true);
  const [leftArrowDisabled, setLeftArrowDisabled] = useState(true);
  const [rightArrowDisabled, setRightArrowDisabled] = useState(false);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [previousModuleClicked, setPreviousModuleClicked] = useState(false);
  const [nextModuleClicked, setNextModuleClicked] = useState(false);

  let pebbleImg;

  const sectionLessonsList = useSelector(
    state => state?.lesson?.sectionLessonsList,
  );

  useEffect(() => {
    if (isNotificationRedirect && notificationLesson) {
      setCurrentLesson(notificationLesson);
      setShowLessonContentModal(true);
      fetchLessonData();
    }
  }, [isNotificationRedirect, notificationLesson]);

  useEffect(() => {
    fetchLessonData();

    document.body.classList.add('h-100', 'overflow-auto');
    document.documentElement.classList.add('h-100', 'overflow-auto');

    const handleWheel = event => {
      if (event.deltaY === 0 && event.deltaX !== 0) {
        handleHorizontalScroll(Math.abs(event.deltaX), event.deltaX);
      }
    };

    window.addEventListener('wheel', handleWheel);

    return () => {
      document.body.classList.remove('h-100', 'overflow-auto');
      document.documentElement.classList.remove('h-100', 'overflow-auto');
      window.removeEventListener('wheel', handleWheel);
    };
  }, []);

  useEffect(() => {
    document.body.scrollLeft = 0;
    document.documentElement.scrollLeft = 0;
  }, [currentLesson]);

  const calculateProgress = (completed, total) => {
    return total === 0 ? 0 : (completed / total) * 100;
  };

  const fetchLessonData = async () => {
    await dispatch(
      getSectionsLesson({sectionId: id, loaderDispatch, isPaginated: false}),
    );
  };

  const handleButtonClick = lesson => {
    setCurrentLesson(lesson);
    setShowLessonContentModal(true);
  };

  const handleHorizontalScroll = (distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      document.body.scrollLeft += step;
      scrollAmount += Math.abs(step);
      const maxScrollLeft =
        document.body.scrollWidth - document.body.clientWidth;
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
      if (document.body.scrollLeft === 0) {
        setLeftArrowDisabled(true);
      } else if (Math.ceil(document.body.scrollLeft) >= maxScrollLeft) {
        setRightArrowDisabled(true);
      } else {
        setLeftArrowDisabled(false);
        setRightArrowDisabled(false);
      }
    });
  };

  const handleNextLessonClicked = () => {
    setNextModuleClicked(true);
    setIsSpeaking(false);
    const currentLessonIndex = sectionLessonsList?.findIndex(
      lesson => lesson?.id === currentLesson?.id,
    );
    if (currentLessonIndex === -1) {
      console.error('Current lesson not found in the lesson list.');
      return;
    }
    const nextLessonIndex = currentLessonIndex + 1;
    if (nextLessonIndex < sectionLessonsList?.length) {
      const nextLesson = sectionLessonsList[nextLessonIndex];
      setCurrentLesson(nextLesson);
    } else {
      showToast(
        getLocalizedMessage(intl, 'label.allLessonCompleted'),
        'warning',
      );
      setShowLessonContentModal(false);
      setCurrentLesson(null);
      setMyLessonInformation(null);
      return;
    }
  };

  const handlePreviousLessonClicked = () => {
    setPreviousModuleClicked(true);
    setIsSpeaking(false);
    const currentLessonIndex = sectionLessonsList?.findIndex(
      lesson => lesson?.id === currentLesson?.id,
    );
    if (currentLessonIndex === -1) {
      console.error('Current lesson not found in the lesson list.');
      return;
    }
    const previousLessonIndex = currentLessonIndex - 1;
    if (previousLessonIndex >= 0) {
      const previousLesson = sectionLessonsList[previousLessonIndex];
      setCurrentLesson(previousLesson);
    } else {
      showToast(
        getLocalizedMessage(intl, 'label.firstLessonReached'),
        'warning',
      );
    }
  };

  switch (slug) {
    case 'tropical-island':
      pebbleImg = '/images/shell.svg';
      break;
    case 'urban-island':
      pebbleImg = '/images/dustbin.svg';
      break;
    case 'rural-island':
      pebbleImg = '/images/corn.svg';
      break;
    case 'fantasy-island':
      pebbleImg = '/images/fantasyStone.svg';
      break;
    default:
      pebbleImg = '/images/pebble.svg';
  }

  return (
    <>
      <div
        className={`student-lesson-wrapper lesson-bg-${section} student-common-lesson`}>
        <div className="student-lesson-container">
          <div className="play-buttons">
            {sectionLessonsList?.map((lesson, index) => (
              <LessonPath
                key={lesson.id}
                lesson={lesson}
                index={index}
                handleButtonClick={handleButtonClick}
                calculateProgress={calculateProgress}
                pebbleImg={pebbleImg}
              />
            ))}
          </div>
        </div>
      </div>

      {showLessonContentModal && currentLesson && (
        <CustomModalStudent
          open={showLessonContentModal}
          modalWidth={'90%'}
          showHeader
          showActionButtons
          dialogHeader={
            <LessonContentHeader
              currentLesson={currentLesson}
              myLessonInformation={myLessonInformation}
              isStudentRole={true}
            />
          }
          dialogActionButtons={
            <LessonContentActionButtons
              setShowLessonContentModal={setShowLessonContentModal}
              setCurrentLesson={setCurrentLesson}
              nextLessonClicked={handleNextLessonClicked}
              previousLessonClicked={handlePreviousLessonClicked}
              sectionId={id}
              myLessonInformation={myLessonInformation}
              setMyLessonInformation={setMyLessonInformation}
              setIsSpeaking={setIsSpeaking}
            />
          }>
          <StudentViewLessonContent
            currentLesson={currentLesson}
            isPreviewAvailable={false}
            setMyLessonInformation={setMyLessonInformation}
            isSpeaking={isSpeaking}
            setIsSpeaking={setIsSpeaking}
            nextModuleClicked={nextModuleClicked}
            previousModuleClicked={previousModuleClicked}
            setNextModuleClicked={setNextModuleClicked}
            setPreviousModuleClicked={setPreviousModuleClicked}
          />
        </CustomModalStudent>
      )}

      <HorizontalScrollButtons />
    </>
  );
}

export default StudentLesson;
