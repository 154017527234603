import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import SelectDropdown from '../../components/SelectDropdown/SelectDropdown';
import InputLabel from '../../components/InputLabel/InputLabel.js';
import {useIntl} from 'react-intl';
import UploadFileImage from '../../components/UploadFileImage/UploadFileImage.js';

function SchoolDetailForm({
  onSubmit,
  onChange,
  formData,
  errors,
  editMode,
  id,
  setFileId,
  previewUrl,
  imageName,
  setImageName,
  setPreviewUrl,
}) {
  const intl = useIntl();
  const DesignationList = useSelector(
    state => state.school?.DesignationList.data,
  );
  const CountriesList = useSelector(state => state?.school?.CountriesList.data);
  const StatesList = useSelector(state => state?.school?.StatesList.data);
  const DistrictsList = useSelector(state => state?.school?.districtData);

  const handleInputChange = e => {
    const {name, value} = e.target;
    onChange(e);
    if (name) {
      const updatedEvent = {
        target: {
          name: name,
          value: value,
        },
      };
      onChange(updatedEvent);
    }
  };

  const getDistrictList = () => {
    return DistrictsList?.map(opt => ({
      ...opt,
      id: opt?.district_id,
      name: opt?.district_name,
    }));
  };

  return (
    <div className="container add-school-wrapper">
      <form onSubmit={onSubmit}>
        <div className="row">
          <div className="mb-3 col-md-6">
            <InputLabel
              type="text"
              name="name"
              label={intl.formatMessage({id: 'label.school_name'})}
              required={true}
              placeholder={intl.formatMessage({id: 'label.enter_school_name'})}
              id="name"
              value={formData.name}
              onChange={onChange}
              htmlFor="schoolName"
              disabled={!editMode && id}
            />
            {errors.name && <div className="error-message">{errors.name}</div>}
          </div>
          <div className="mb-3 col-md-6">
            <label htmlFor="file" className="form-label cus-form-label">
              {intl.formatMessage({id: 'label.profile_image'})}
            </label>
            <UploadFileImage
              setFileId={setFileId}
              previewUrl={previewUrl}
              setPreviewUrl={setPreviewUrl}
              acceptType={'image/*'}
              isDisabled={!editMode && id}
              hideImageTag
              imageName={imageName}
              setImageName={setImageName}
            />
          </div>

          <div className="mb-3 col-md-6">
            <InputLabel
              type="url"
              name="website_url"
              label={intl.formatMessage({id: 'label.website_url'})}
              required={true}
              placeholder={intl.formatMessage({id: 'label.enter_website_url'})}
              id="website_url"
              value={formData.website_url}
              onChange={onChange}
              htmlFor="websiteUrl"
              disabled={!editMode && id}
            />
            {errors.website_url && (
              <div className="error-message">{errors.website_url}</div>
            )}
          </div>
          <div className="mb-3 col-md-6">
            <div className="">
              <SelectDropdown
                options={CountriesList}
                type="number"
                id="country_id"
                name="country_id"
                value={formData.country_id}
                onChange={handleInputChange}
                placeholder={intl.formatMessage({id: 'label.enter_country'})}
                disabled={CountriesList?.length === 0 || (!editMode && id)}
                htmlFor="country"
                label={intl.formatMessage({id: 'label.country'})}
                required={true}
              />
              {errors.country_id && (
                <div className="error-message">{errors.country_id}</div>
              )}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="mb-3 col-md-6">
            <div>
              <SelectDropdown
                options={StatesList}
                type="number"
                id="state_id"
                placeholder={intl.formatMessage({id: 'label.enter_state'})}
                name="state_id"
                value={formData.state_id}
                onChange={handleInputChange}
                disabled={
                  StatesList?.length === 0 ||
                  !formData.country_id ||
                  (!editMode && id)
                }
                htmlFor="state"
                label={intl.formatMessage({id: 'label.state'})}
                required={true}
              />
              {errors.state_id && (
                <div className="error-message">{errors.state_id}</div>
              )}
            </div>
          </div>

          <div className="mb-3 col-md-6">
            <div>
              {/* <pre>{formData.district_id}</pre> */}
              {/* <SelectDropdown
                options={getDistrictList()}
                type="number"
                id="district_id"
                name="district_id"
                value={formData.district_id}
                onChange={handleInputChange}
                placeholder={intl.formatMessage({
                  id: 'label.enter_school_district',
                })}
                disabled={
                  DistrictsList?.length === 0 ||
                  !formData.state_id ||
                  (!editMode && id)
                }
                htmlFor="schoolDistrict"
                label={intl.formatMessage({id: 'label.school_district'})}
                required={true}
              />
              {errors.district_id && (
                <div className="error-message">{errors.district_id}</div>
              )} */}

              <InputLabel
                type="text"
                name="district_name"
                label={intl.formatMessage({id: 'label.school_district'})}
                required={true}
                placeholder={intl.formatMessage({
                  id: 'label.enter_school_district',
                })}
                id="district_name"
                value={formData.district_name}
                onChange={onChange}
                htmlFor="schoolDistrict"
                disabled={!editMode && id}
              />
              {errors.name && (
                <div className="error-message">{errors.district_name}</div>
              )}
            </div>
          </div>

          <div className="mb-3 col-md-6">
            <InputLabel
              type="text"
              name="city"
              label={intl.formatMessage({id: 'label.city'})}
              placeholder={intl.formatMessage({id: 'label.enter_city_name'})}
              id="city"
              value={formData.city}
              onChange={onChange}
              htmlFor="city"
              disabled={!editMode && id}
            />
            {errors.city && <div className="error-message">{errors.city}</div>}
          </div>

          <div className="mb-6 col-md-6">
            <InputLabel
              type="text"
              name="address"
              label={intl.formatMessage({id: 'label.full_address'})}
              required={true}
              placeholder={intl.formatMessage({id: 'label.enter_full_address'})}
              id="address"
              value={formData.address}
              onChange={onChange}
              htmlFor="fullAddress"
              disabled={!editMode && id}
            />
            {errors.address && (
              <div className="error-message">{errors.address}</div>
            )}
          </div>
          <div className="mb-3 col-md-6">
            <InputLabel
              type="text"
              name="zip_code"
              label={intl.formatMessage({id: 'label.zip_code'})}
              required={true}
              placeholder={intl.formatMessage({id: 'label.enter_zip_code'})}
              id="zip_code"
              value={formData.zip_code}
              onChange={onChange}
              htmlFor="zipCode"
              disabled={!editMode && id}
            />
            {errors.zip_code && (
              <div className="error-message">{errors.zip_code}</div>
            )}
          </div>
        </div>

        <h2>{intl.formatMessage({id: 'label.contact_details_school'})}</h2>
        <div className="row">
          <div className="mb-3 col-md-6">
            <InputLabel
              type="text"
              name="contact_first_name"
              label={intl.formatMessage({id: 'label.contact_first_name'})}
              required={true}
              placeholder={intl.formatMessage({
                id: 'label.enter_contact_form_name',
              })}
              id="contact_first_name"
              value={formData.contact_first_name}
              onChange={onChange}
              htmlFor="contactFirstName"
              disabled={!editMode && id}
            />
            {errors.contact_first_name && (
              <div className="error-message">{errors.contact_first_name}</div>
            )}
          </div>
          <div className="mb-3 col-md-6">
            <InputLabel
              type="text"
              name="contact_last_name"
              label={intl.formatMessage({id: 'label.contact_last_name'})}
              required={true}
              placeholder={intl.formatMessage({
                id: 'label.enter_contact_last_name',
              })}
              id="contact_last_name"
              value={formData.contact_last_name}
              onChange={onChange}
              htmlFor="contactLastName"
              disabled={!editMode && id}
            />
            {errors.contact_last_name && (
              <div className="error-message">{errors.contact_last_name}</div>
            )}
          </div>

          <div className="mb-3 col-md-6">
            <InputLabel
              type="email"
              name="contact_email"
              label={intl.formatMessage({id: 'label.email_address'})}
              required={true}
              placeholder={intl.formatMessage({
                id: 'label.enter_email_address',
              })}
              id="contact_email"
              value={formData.contact_email}
              onChange={onChange}
              htmlFor="emailAddress"
              disabled={!editMode && id}
            />
            {errors.contact_email && (
              <div className="error-message">{errors.contact_email}</div>
            )}
          </div>

          <div className="mb-3 col-md-6">
            <div>
              <SelectDropdown
                options={DesignationList}
                type="number"
                id="contact_designation_id"
                name="contact_designation_id"
                value={formData.contact_designation_id}
                onChange={handleInputChange}
                placeholder={intl.formatMessage({
                  id: 'label.select_designation',
                })}
                disabled={DesignationList?.length === 0 || (!editMode && id)}
                htmlFor="designation"
                label={intl.formatMessage({id: 'label.designation'})}
              />
            </div>
          </div>

          <div className="mb-3 col-md-6">
            <InputLabel
              type="number"
              name="contact_phone_no"
              label={intl.formatMessage({id: 'label.contact_number'})}
              required={true}
              placeholder={intl.formatMessage({
                id: 'label.enter_contact_number',
              })}
              id="contact_phone_no"
              value={formData.contact_phone_no}
              onChange={onChange}
              htmlFor="contactNo"
              disabled={!editMode && id}
            />
            {errors.contact_phone_no && (
              <div className="error-message">{errors.contact_phone_no}</div>
            )}
          </div>
          <div className="mb-3 col-md-6">
            <InputLabel
              type="number"
              name="contact_alternate_no"
              label={intl.formatMessage({id: 'label.alternate_contact_number'})}
              placeholder={intl.formatMessage({
                id: 'label.enter_contact_number',
              })}
              id="contact_alternate_no"
              value={formData.contact_alternate_no}
              onChange={onChange}
              htmlFor="alternateContactNo"
              disabled={!editMode && id}
            />
          </div>
        </div>
      </form>
    </div>
  );
}
export default SchoolDetailForm;
