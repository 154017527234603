import React from 'react';
import CustomModalStudent from '../../../components/CustomModalStudent/CustomModalStudent';
import {useIntl} from 'react-intl';
import StudentProfileLeftBar from './StudentProfileLeftBar/StudentProfileLeftBar';
import StudentProfileRightBar from './StudentProfileRightBar/StudentProfileRightBar';
import './StudentSetting.scss';

function StudentSetting({showSettings, closeModal}) {
  const intl = useIntl();

  const handleCloseModal = () => {
    closeModal(false);
  };

  const dialogHeaderContent = (
    <div className="help-header-wrapper">
      <div onClick={handleCloseModal} className="back-icon">
        <img src="/images/back.svg" alt="back" />
      </div>
      <h2 className="help-header text-shadow">
        {intl.formatMessage({id: 'label.settings'})}
      </h2>
    </div>
  );

  return (
    <div className="student-setting-modal-outer">
      <CustomModalStudent
        open={showSettings}
        handleClose={handleCloseModal}
        backgroundBlue={true}
        modalWidth={'90%'}
        modalHeight={'97%'}
        marginTop={'1%'}
        showHeader
        dialogHeader={dialogHeaderContent}>
        <div className="student-setting-modal-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col">
                <StudentProfileLeftBar />
              </div>

              <div className="col-3 sidebar">
                <StudentProfileRightBar />
              </div>
            </div>
          </div>
        </div>
      </CustomModalStudent>
    </div>
  );
}

export default StudentSetting;
