import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import CustomDialogue from '../../../components/CustomDialogue/CustomDialogue';
import './StudentMenu.scss';
import ThreeDButton from '../../../components/ThreeDButton/ThreeDButton';
import {MANAGE_ISLAND_LIST} from '../../../constants/routePaths';
import {useNavigate} from '../../../routes';
import StudentSetting from '../StudentSetting/StudentSetting';
import StudentDashboard from '../StudentDashboard/StudentDashboard';
import StudentBookmark from '../StudentBookmark/StudentBookmark';

function StudentMenu() {
  const intl = useIntl();
  const [listingOpen, setListingOpen] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [showDashboard, setShowDashboard] = useState(false);
  const [showBookmark, setShowBookmark] = useState(false);
  const navigate = useNavigate();

  const menuItems = [
    {
      label: intl.formatMessage({id: 'label.all_islands'}),
      redirectPath: MANAGE_ISLAND_LIST,
    },
    {
      label: intl.formatMessage({id: 'label.bookmarks'}),
      redirectPath: '/',
      handleMenuCall: () => {
        handleCloseListing();
        setShowBookmark(true);
      },
    },

    {
      label: intl.formatMessage({id: 'label.dashboard'}),
      redirectPath: '/',
      handleMenuCall: () => {
        handleCloseListing();
        setShowDashboard(true);
      },
    },
    {
      label: intl.formatMessage({id: 'label.profile'}),
      handleMenuCall: () => {
        handleCloseListing();
        setShowSettings(true);
      },
    },
  ];

  const handleDashboardClick = () => {
    setListingOpen(true);
  };

  const handleCloseListing = () => {
    setListingOpen(false);
  };

  const handleMenuItemClick = redirectPath => {
    navigate(redirectPath);
    handleCloseListing();
  };

  const closeBookmarkModal = () => {
    setShowBookmark(false);
  };

  return (
    <div className="dashboard-wrapper cursor">
      {listingOpen ? (
        <div onClick={handleCloseListing}>
          <img src="/images/blue-cross.svg" alt="Close" />
        </div>
      ) : (
        <div onClick={handleDashboardClick}>
          <img src="/images/dashboardIcon.svg" alt="Dashboard" />
        </div>
      )}

      <CustomDialogue
        modalWrapperClass="student_menu_modal"
        open={listingOpen}
        handleClose={handleCloseListing}
        cancelText="Cancel"
        confirmText="Confirm"
        dialogueTitle="Transparent Listing"
        onCancel={handleCloseListing}
        hideTitle
        hideActionsButtons
        isStudentDashboard>
        <div className="transparent-listing row">
          {menuItems.map((item, index) => (
            <div className="col-md-4 flex-center" key={index}>
              <ThreeDButton
                labelClassName="montserrat-bold"
                className="mt-3 text-capitalize"
                labelText={item.label}
                handleClick={() => {
                  if (item.handleMenuCall) {
                    item.handleMenuCall();
                  } else {
                    navigate(item.redirectPath);
                    handleCloseListing();
                  }
                }}
              />
            </div>
          ))}
        </div>
      </CustomDialogue>
      {showSettings && (
        <StudentSetting
          showSettings={showSettings}
          closeModal={setShowSettings}
        />
      )}
      {showDashboard && (
        <StudentDashboard
          showDashboard={showDashboard}
          setShowDashboard={setShowDashboard}
          closeModal={setShowDashboard}
        />
      )}
      {setShowBookmark && (
        <StudentBookmark
          showBookmark={showBookmark}
          setShowBookmark={setShowBookmark}
          closeModal={closeBookmarkModal}
        />
      )}
    </div>
  );
}

export default StudentMenu;
