import {createAsyncThunk} from '@reduxjs/toolkit';
import axiosPrivateInstance from '../../api/axiosPrivateInstance';
import {getLocalizedMessage} from '../../utilityFunction/helper';
import {showToast} from '../../components/Toast/Toast';
import {MANAGE_STUDENTS_PATH} from '../../constants/routePaths';
import {setLocalStorageItem} from '../../services/local-storage';
import {setLoading} from '../../globalContext/globalLoader/globalLoaderAction';

export const fetchStudentList = createAsyncThunk(
  'student/fetchStudentList',
  async (
    {
      searchTerm,
      sectionId,
      gradeId,
      currentPage,
      sortOrder,
      schoolId,
      districtId,
      loaderDispatch,
    },
    thunkAPI,
  ) => {
    loaderDispatch(setLoading(true));
    try {
      const params = {
        per_page: 10,
        page: currentPage,
        sort_order: sortOrder,
        ...(searchTerm && {search: searchTerm}),
        ...(sectionId && {section_id: sectionId}),
        ...(gradeId && {grade_id: gradeId}),
        ...(schoolId && {school_id: schoolId}),
        ...(districtId && {district_id: districtId}),
      };

      const response = await axiosPrivateInstance.get('/students', {params});
      loaderDispatch(setLoading(false));
      return {
        currentPage: response.data.data.current_page,
        totalPages: response.data.data.last_page,
        data: response.data.data.data,
      };
    } catch (error) {
      loaderDispatch(setLoading(false));
      throw error.response.data.message;
    }
  },
);
export const fetchGradeList = createAsyncThunk(
  'student/fetchGradeList',
  async (stateId, thunkAPI) => {
    try {
      let url = '/grades';

      const response = await axiosPrivateInstance.get(url);
      return response.data;
    } catch (error) {
      throw error.response.data.message;
    }
  },
);

export const fetchSectionList = createAsyncThunk(
  'student/fetchSectionList',
  async (gradeId, thunkAPI) => {
    try {
      let url = '/sections';
      if (gradeId) {
        url += `?garde_id=${gradeId}`;
      }
      const response = await axiosPrivateInstance.get(url);
      return response.data.data;
    } catch (error) {
      throw error.response.data.message;
    }
  },
);

export const fetchCounsellors = createAsyncThunk(
  'counsellors/fetchCounsellors',
  async ({schoolId}, thunkAPI) => {
    try {
      const response = await axiosPrivateInstance.get('/counsellors', {
        params: {school_id: schoolId},
      });
      return response.data;
    } catch (error) {
      throw error.response.data.message;
    }
  },
);
export const fetchTeachers = createAsyncThunk(
  'teachers/fetchTeachers',
  async ({schoolId}, thunkAPI) => {
    try {
      const response = await axiosPrivateInstance.get(`/teachers`, {
        params: {school_id: schoolId},
      });
      return response.data;
    } catch (error) {
      throw error.response.data.message;
    }
  },
);

export const fetchStudentById = createAsyncThunk(
  'students/fetchStudentById',
  async (studentId, thunkAPI) => {
    try {
      const response = await axiosPrivateInstance.get(`/students/${studentId}`);
      return response.data;
    } catch (error) {
      throw error.response.data.message;
    }
  },
);

export const addStudent = createAsyncThunk(
  'students/addStudent',
  async ({studentData, intl, navigate}, thunkAPI) => {
    try {
      const response = await axiosPrivateInstance.post(
        '/students',
        studentData,
      );
      showToast(getLocalizedMessage(intl, 'success.student_added'), 'success');
      navigate(MANAGE_STUDENTS_PATH);
      return response.data;
    } catch (error) {
      if (error.response.data.code === 'USER_ALREADY_EXIST')
        showToast(getLocalizedMessage(intl, 'label.email_exists'), 'error');
      else if (error.response.data.code === 'USERNAME_ALREADY_EXIST')
        showToast(getLocalizedMessage(intl, 'label.username_exists'), 'error');
      else
        showToast(
          getLocalizedMessage(intl, 'error.somethingWentWrong'),
          'error',
        );
      throw error.response.data.message;
    }
  },
);

export const updateStudent = createAsyncThunk(
  'students/updateStudent',
  async ({id, payload, intl, navigate}, thunkAPI) => {
    try {
      await axiosPrivateInstance.patch(`/students/${id}`, payload);
      showToast(
        getLocalizedMessage(intl, 'success.student_updated'),
        'success',
      );
      navigate(MANAGE_STUDENTS_PATH);
      return payload;
    } catch (error) {
      showToast(getLocalizedMessage(intl, 'error.somethingWentWrong'), 'error');
      throw error.response.data.message;
    }
  },
);

export const updateStudentStatus = createAsyncThunk(
  'students/updateStudentStatus',
  async ({id, intl}, thunkAPI) => {
    try {
      const response = await axiosPrivateInstance.patch(
        `/students/status/${id}`,
      );
      showToast(
        getLocalizedMessage(intl, 'student.success.updated'),
        'success',
      );
      return response.data;
    } catch (error) {
      showToast(getLocalizedMessage(intl, 'error.somethingWentWrong'), 'error');
      throw error.response.data.message;
    }
  },
);
export const resetPasswordStudent = createAsyncThunk(
  'student/resetPassword',
  async ({student_id, password}, thunkAPI) => {
    try {
      const response = await axiosPrivateInstance.post(
        '/students-change-password',
        {
          student_id,
          password,
        },
      );
      showToast(
        getLocalizedMessage(intl, 'label.password_changed_success'),
        'success',
      );
      return response.data;
    } catch (error) {
      const errorMessage = getLocalizedMessage(error.response.data.message);
      showToast(errorMessage, 'error');
      throw error.response.data.message;
    }
  },
);

export const studentPasswordReset = createAsyncThunk(
  'student/passwordReset',
  async ({payload, intl, setError, loaderDispatch}, thunkAPI) => {
    loaderDispatch(setLoading(true));
    try {
      const response = await axiosPrivateInstance.post(
        '/student-reset-password',
        payload,
      );
      const tokenAccess = response?.data?.data?.access_token;
      const userRoleDetails = response?.data?.data?.user?.role;
      setLocalStorageItem('accessToken', tokenAccess);
      setLocalStorageItem('userRole', userRoleDetails);
      loaderDispatch(setLoading(false));
      showToast(getLocalizedMessage(intl, 'login.success'), 'success');
      return response.data;
    } catch (error) {
      loaderDispatch(setLoading(false));
      setError({isError: true, errorMessage: error?.response?.data?.message});
      showToast(getLocalizedMessage(intl, 'link.expired'), 'error');
      throw error.response.data.message;
    }
  },
);
