import React, {useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useIntl} from 'react-intl';
import {PiDotsThreeVertical} from 'react-icons/pi';
import {Typography} from '@mui/material';
import CustomTable from '../../components/CustomTable/CustomTable';
import DropdownMenu from '../../components/DropdownMenu/DropdownMenu';
import DeactivateModal from '../../components/DeactivateModal/DeactivateModal';
import ImportBulkModal from '../../components/ImportBulkModal/ImportBulkModal';
import TabsFilterSection from '../../components/TabsFilterSection/TabsFilterSection';
import {
  fetchStaffList,
  updateStaffStatus,
} from '../../store/actions/staffActions';
import {fetchStudentList} from '../../store/actions/studentActions.js';
import {
  fetchSchoolList,
  fetchSchoolById,
} from '../../store/actions/schoolActions';
import {
  uploadTemplate,
  downloadTemplate,
} from '../../store/actions/bulkImportActions';
import {
  checkIsSuperAdmin,
  concatenateStrings,
  getLocalizedMessage,
  getStaffTabsOptions,
  getStatusText,
} from '../../utilityFunction/helper';
import {STAFF_TEMPLATE_TYPE} from '../../utilityFunction/constants';
import {useNavigate} from '../../routes/index.js';
import {ADD_STAFF_PATH, UPDATE_STAFF_PATH} from '../../constants/routePaths.js';
import useUserRole from '../../hooks/useUserRole';
import UserRoles from '../../constants/userRoles';
import {GlobalLoaderContext} from '../../globalContext/globalLoader/globalLoaderProvider';

export default function ManageStaffListing() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [_, loaderDispatch] = useContext(GlobalLoaderContext);
  const {isAuthenticated} = useSelector(state => state?.auth);
  const schoolList = useSelector(
    state => state?.school?.schoolList?.data?.data?.data,
  );
  const studentList = useSelector(state => state.student.studentList?.data);
  const {staffsList} = useSelector(state => state?.staff);
  const userRole = useUserRole();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const [showImportModal, setShowImportModal] = useState(false);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [openDeactivateModal, setOpenDeactivateModal] = useState(false);

  // @TODO - For fetching school data
  const [schoolId, setSchoolId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedSection, setSelectedSection] = useState('');
  const [selectedGrade, setSelectedGrade] = useState('');
  const [districtId, setDistrictId] = useState(null);
  const [schoolData, setSchoolData] = useState(null);

  const userSchoolId = useSelector(
    state => state?.auth?.data?.data?.data?.user?.school?.id,
  );

  useEffect(() => {
    if (isAuthenticated) {
      fetchStaffData();

      // @TODO - for fetching school data
      fetchStudentData();
    }
  }, [
    isAuthenticated,
    sortOrder,
    currentPage,
    searchTerm,
    selectedRole,
    userSchoolId,
  ]);

  useEffect(() => {
    if (isAuthenticated && showSchoolDropdown()) {
      dispatch(fetchSchoolList({}));
    }
  }, [isAuthenticated]);

  const showSchoolDropdown = () => {
    const rolePermissions = [UserRoles.SUPER_ADMIN];
    return rolePermissions?.includes(userRole);
  };

  const showAddImportBtns = () => {
    const rolePermissions = [UserRoles.SCHOOL_ADMIN];
    return rolePermissions?.includes(userRole);
  };

  const fetchStaffData = async () => {
    await dispatch(
      fetchStaffList({
        searchTerm,
        currentPage,
        sortOrder,
        selectedRole,
        schoolId: userSchoolId,
        loaderDispatch,
      }),
    );
  };

  const fetchStudentData = async () => {
    try {
      await dispatch(
        fetchStudentList({
          searchTerm,
          sectionId: selectedSection,
          gradeId: selectedGrade,
          currentPage,
          sortOrder,
          schoolId: userSchoolId,
          districtId,
          loaderDispatch,
        }),
      );
    } catch (error) {
      console.log('Error fetching school data:', error);
    } finally {
      setLoading(false);
    }
  };

  const onSortChange = () => {
    const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    setSortOrder(newSortOrder);
  };

  const onPageChange = page => {
    setCurrentPage(page);
  };

  const onSearch = e => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const onTabChange = selectTabId => {
    setSelectedRole(selectTabId);
    setCurrentPage(1);
  };

  const templateDownloadHandler = async () => {
    dispatch(
      downloadTemplate({
        templateType: STAFF_TEMPLATE_TYPE,
        intl,
        templateName: 'Staff_Template.xlsx',
        loaderDispatch,
      }),
    );
    setShowImportModal(false);
  };

  const fileImportHandler = async file => {
    dispatch(uploadTemplate({file, url: '/bulk-upload-staff', intl})).then(
      () => {
        fetchStaffData();
        setShowImportModal(false);
      },
    );
  };

  const handleDeactivateConfirm = async () => {
    if (selectedStaff?.id) {
      setOpenDeactivateModal(false);
      dispatch(updateStaffStatus({id: selectedStaff?.id, intl})).then(() => {
        setSelectedStaff(null);
        fetchStaffData();
      });
    }
  };

  const handleAvatarClick = staff => {
    if (selectedStaff?.id === staff?.id) setSelectedStaff(null);
    else setSelectedStaff(staff);
  };
  const handleAddStaff = () => {
    navigate(ADD_STAFF_PATH);
  };

  const viewProfile = id => {
    navigate(`${UPDATE_STAFF_PATH.replace(':id', id)}`);
  };
  const onSelectChange = value => {
    setSchoolId(value);
  };

  const isSuperAdminRole = checkIsSuperAdmin(userRole);

  const TABLE_HEAD = [
    {
      id: 'idNumber',
      label: intl.formatMessage({id: 'label.idNumber'}),
      alignRight: false,
      renderColumn: staff => {
        return (
          <Typography variant="subtitle">
            {staff?.employee_id || '-'}
          </Typography>
        );
      },
    },
    {
      id: 'staffMembersName',
      label: intl.formatMessage({id: 'label.staffMemberName'}),
      alignRight: false,
      renderColumn: staff => {
        return (
          <div key={staff?.id} className="s_name_address d-flex">
            <div className="s_logo">
              <img
                src={
                  staff?.media
                    ? staff?.media?.path
                    : '/images/studentPlaceholder.png'
                }
                alt="Staff Member"
              />
            </div>

            <div>
              <div className="s_name">{`${staff?.first_name} ${
                staff?.last_name || ''
              }`}</div>
              <div className="s_address">
                {staff?.staff_types === 'school_admin'
                  ? 'School admin'
                  : staff?.staff_types}
              </div>
            </div>
          </div>
        );
      },
    },
    {
      id: 'staffEmailAddress',
      label: intl.formatMessage({id: 'label.staffEmailAddress'}),
      alignRight: false,
      dataKey: 'email',
    },
    {
      id: 'noOfStudents',
      label: intl.formatMessage({id: 'label.noStudents'}),
      alignRight: false,
      renderColumn: staff => {
        return (
          <Typography variant="subtitle">
            {staff?.student_count || '-'}
          </Typography>
        );
      },
    },
    {
      id: 'quickActions',
      label: intl.formatMessage({id: 'label.quick_actions'}),
      alignRight: false,
      dataKey: '',
      renderColumn: staff => {
        return (
          <div className="d-flex actions-wrapper">
            {/* <div className="actions gap-4">
              <img src="/images/message-text.png" alt="message-chat" />
              <img src="/images/send.svg" alt="send" />
            </div> */}
            <div
              className={
                staff?.id === selectedStaff?.id
                  ? 'edit-delete selected-action'
                  : 'edit-delete'
              }>
              <div style={{position: 'relative'}}>
                <PiDotsThreeVertical onClick={() => handleAvatarClick(staff)} />
                {staff?.id === selectedStaff?.id && (
                  <DropdownMenu isOpen={true}>
                    <div
                      className="pointer"
                      onClick={() => viewProfile(staff.id)}>
                      {intl.formatMessage({id: 'label.view-profile'})}
                    </div>

                    {!isSuperAdminRole && (
                      <div
                        className="pointer"
                        onClick={() => setOpenDeactivateModal(true)}>
                        {getStatusText(staff?.is_active, intl)}
                      </div>
                    )}
                  </DropdownMenu>
                )}
              </div>
            </div>
          </div>
        );
      },
    },
  ];

  // @TODO - for fetching school data
  useEffect(() => {
    const rolePermissions = [UserRoles.SCHOOL_ADMIN, UserRoles.COUNSELLOR];
    if (rolePermissions?.includes(userRole)) {
      setLoading(true);
      const schoolId = studentList?.data[0]?.school.id;
      setSchoolId(userSchoolId);
    }
  }, [studentList]);
  useEffect(() => {
    const rolePermissions = [UserRoles.SCHOOL_ADMIN, UserRoles.COUNSELLOR];

    if (rolePermissions?.includes(userRole) && schoolId) {
      dispatch(fetchSchoolById(schoolId))
        .then(response => {
          const schoolData = response.payload.data;
          setSchoolData(schoolData);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [schoolId]);

  return (
    <div>
      <div className="d-flex student-detail-wrapper">
        <div className="school-label">
          {intl.formatMessage({id: 'label.details_of_staff'})}
        </div>
        {showAddImportBtns() && (
          <div className="add-import-btn">
            <div className="add-student-btn">
              <button onClick={handleAddStaff}>
                {intl.formatMessage({id: 'label.addStaff'})}
              </button>
            </div>
            <div className="import-btn">
              <button onClick={() => setShowImportModal(true)}>
                {' '}
                <img className="me-1" src="/images/importIcon.svg" />{' '}
                {intl.formatMessage({id: 'label.import'})}
              </button>
            </div>
          </div>
        )}
      </div>

      <TabsFilterSection
        tabsOptions={getStaffTabsOptions(intl)}
        handleTabSelect={onTabChange}
        onSearchChange={onSearch}
        handleSort={onSortChange}
        sortOrder={sortOrder}
        searchPlaceholder={intl.formatMessage({id: 'label.search_name_class'})}
        selectedTabId={selectedRole}
        showSelect={showSchoolDropdown()}
        dropdownOptions={schoolList}
        dropdownPlaceholder={intl.formatMessage({
          id: 'label.select.school',
        })}
        handleSelectChange={onSelectChange}
      />

      <CustomTable
        tableHead={TABLE_HEAD}
        tableData={staffsList?.data}
        currentPage={currentPage}
        onPageChange={onPageChange}
        totalPages={staffsList?.last_page}
        totalCount={staffsList?.total}
      />

      {showImportModal && (
        <ImportBulkModal
          open={showImportModal}
          handleClose={() => setShowImportModal(false)}
          modalTitle={'Staff Bulk Upload'}
          onDownloadTemplate={templateDownloadHandler}
          onImportFile={fileImportHandler}
        />
      )}

      {openDeactivateModal && (
        <DeactivateModal
          open={openDeactivateModal}
          setOpen={setOpenDeactivateModal}
          handleDeactivate={handleDeactivateConfirm}
          confirmText={getStatusText(selectedStaff?.is_active, intl)}
          modalContentText={
            !selectedStaff?.is_active
              ? getLocalizedMessage(intl, 'staff.statusModal.activate', {
                  staffMember: selectedStaff?.staff_types,
                })
              : getLocalizedMessage(intl, 'staff.statusModal.deactivate', {
                  staffMember: selectedStaff?.staff_types,
                })
          }
        />
      )}
    </div>
  );
}
