import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import CustomDialogue from '../../../components/CustomDialogue/CustomDialogue';
import InputLabel from '../../../components/InputLabel/InputLabel';
import SelectDropdown from '../../../components/SelectDropdown/SelectDropdown.js';
import {useIntl} from 'react-intl';
import {addSectionFieldName} from '../../../validationSchema/AddSectionSchema.js';
import {updateModuleName} from '../../../store/actions/sectionActions.js';

const AddSection = ({
  open,
  handleClose,
  handleSubmit,
  values,
  handleChange,
  errors,
  touched,
  handleBlur,
  setFieldValue,
  sectionData,
  editMode,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const sectionObjectsList = useSelector(
    state => state.section.sectionObjectsList,
  );

  const onObjectChange = e => {
    const selectedObjectId = e.target.value;
    setFieldValue(addSectionFieldName.OBJECT, selectedObjectId);
  };

  useEffect(() => {
    if (sectionData && editMode) {
      setFieldValue(
        addSectionFieldName.MODULE_ELEMENT_NAME,
        sectionData.module_element.name || '',
      );
      setFieldValue(addSectionFieldName.NAME, sectionData.name || '');
      setFieldValue(addSectionFieldName.TOKEN, sectionData.token_credits || '');
      if (sectionData.module_element) {
        setFieldValue(
          addSectionFieldName.OBJECT,
          sectionData.module_element.id || '',
        );
      }
    } else {
      setFieldValue(addSectionFieldName.NAME, '');
      setFieldValue(addSectionFieldName.TOKEN, '');
      setFieldValue(addSectionFieldName.OBJECT, '');
      setFieldValue(addSectionFieldName.MODULE_ELEMENT_NAME, '');
    }
  }, [sectionData, setFieldValue, editMode]);

  return (
    <CustomDialogue
      open={open}
      handleClose={handleClose}
      cancelText={intl.formatMessage({
        id: 'label.cancel',
      })}
      confirmText={intl.formatMessage({
        id: 'label.save',
      })}
      dialogueTitle={intl.formatMessage({
        id: 'label-section-details',
      })}
      onCancel={handleClose}
      hideTitle={false}
      onSubmit={handleSubmit}>
      <div className="container">
        <div className="row mt-4">
          <div className="mb-3 col-md-12">
            <InputLabel
              name={addSectionFieldName.NAME}
              label={intl.formatMessage({
                id: 'label.section_name',
              })}
              placeholder={intl.formatMessage({
                id: 'label.enter_section_name',
              })}
              required
              value={values[addSectionFieldName.NAME]}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched[addSectionFieldName.NAME] &&
                errors[addSectionFieldName.NAME]
              }
              // disabled={(!editMode && id)}
            />
          </div>
          <div className="mb-3 col-md-12">
            <InputLabel
              name={addSectionFieldName.TOKEN}
              label={intl.formatMessage({
                id: 'label.token_number',
              })}
              placeholder={intl.formatMessage({
                id: 'label.enter_token_number',
              })}
              required
              value={values[addSectionFieldName.TOKEN]}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched[addSectionFieldName.TOKEN] &&
                errors[addSectionFieldName.TOKEN]
              }
              // disabled={(!editMode && id)}
            />
          </div>
          {!editMode ? (
            <div className="mb-3 col-md-12">
              <SelectDropdown
                options={sectionObjectsList}
                label={intl.formatMessage({id: 'label.select_object'})}
                placeholder={intl.formatMessage({
                  id: 'label.select_object',
                })}
                required
                value={values[addSectionFieldName.OBJECT]}
                onChange={e => onObjectChange(e)}
                onBlur={handleBlur}
                error={
                  touched[addSectionFieldName.OBJECT] &&
                  errors[addSectionFieldName.OBJECT]
                }
                disabled={sectionObjectsList?.length === 0}
              />
            </div>
          ) : null}
          {/* @TODO - For changing Module Name */}
          {editMode ? (
            <div className="mb-3 col-md-12">
              <InputLabel
                name={addSectionFieldName.MODULE_ELEMENT_NAME}
                label="Module Element Name"
                placeholder="Enter Module Element Name"
                required
                value={values[addSectionFieldName.MODULE_ELEMENT_NAME]}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  touched[addSectionFieldName.MODULE_ELEMENT_NAME] &&
                  errors[addSectionFieldName.MODULE_ELEMENT_NAME]
                }
              />
            </div>
          ) : null}
        </div>
      </div>
    </CustomDialogue>
  );
};

export default AddSection;
