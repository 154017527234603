const locale = {
  // en locale
  'label.welcome': 'Welcome to GradRock!',
  'label.login-subtitle':
    'Your college or career journey starts here. We are glad to have you on the island! ',
  'label.username': 'Username',
  'label.password': 'Password',
  'label.login': 'Login',
  'label.logout': 'Logout',
  'label.greetings': 'Hi',
  'label.dashboard': 'Dashboard',
  'label.email': 'Enter your email address',
  'label.profile': 'Profile',
  'label.forgot-password': 'Forgot Password?',
  'label.forgot-your-password': 'Forgot your password?',
  'label.forgot-password-subtitle':
    'Enter the email address you used when you joined & we’ll send you instructions to log in.',
  'label.forgot-password-button': 'Send Login Instructions',
  'label.super_admin_greeting': 'Manage Schools',
  'label.school_admin_greeting': 'Manage Students',
  'label.student_greeting': 'Hello',
  'label.enter-new-password': 'Enter New Password',
  'label.re-new-password': 'Re-enter Password',
  'label.new-password': 'New Password',
  'label.new-password-subtitle':
    'Enter your new password making sure it’s different from the old password.',
  'label.submit': 'Submit',
  'label.manage': 'Manage',
  'label.tropical_island': 'Tropical Island',
  'label.manage-schools': 'Manage Schools',
  'label.manage-tasks': 'Manage tasks',
  'label.school-admins': 'School Admins',
  'label.back-to-login': 'Back to Login',
  'label.reset-password': 'Reset Password',
  'label.manage-staff': 'Manage Staff',
  'label.manage-students': 'Manage Students',
  'label.manage-counsellor': 'Manage counselor',
  'label.password-reset-success':
    'Your password has been successfully reset. Click below to log in.',
  'label.password-no-match': 'Passwords do not match',
  'label.forgot-password-email':
    'We\ve just sent you an email. Please check your inbox & use the button provided to securely log in.',
  'label.system-settings': 'System Settings',
  'label.forgot-password-email-message-one': 'We\ve just sent you an email on',
  'label.forgot-password-email-message-two':
    'Please check your inbox & use the button provided to securely log in.',
  'label.view-profile': 'View Profile',
  'label.delete': 'Delete',
  'label.details-schools': 'Details of Schools',
  'label.add-school': 'Add School',
  'label.import': 'Import',

  // 'label.forgot-password': 'Forgot your password?',
  // 'label.back-to-login': 'Back to Login',
  'label.details-districts': 'School Districts',
  'label.add-new': 'Add New',
  'label.districts': 'Districts',
  'label.search_name_zip': 'Search by a name or zip code',
  'label.details_of_school': 'Details of School',
  'label.grades': 'Grades',
  'label.sections': 'Sections',
  'label.search_name_class': 'Search by name or class',

  'label.details_of_staff': 'Details of Staff',
  'label.details_of_students': 'Details of Students',

  //errors
  'label.password-criteria':
    'Password must contain at least 8 characters including lowercase, uppercase, special character, and number',

  //table headers
  'label.school-name-address': 'School Name & Address',
  'label.school-email': 'School Email Address',
  'label.contact': 'Contact No.',
  'label.license': 'License Status',
  'label.actions': 'Quick Actions',
  'label.school-district-name': 'School District Name',
  'label.state-name': 'State Name',
  'label.intermediate-unit-name': 'Intermediate Unit Name',

  //buttons

  'label.save-next': 'Save & Next',
  'label.register': 'Register',
  'label.cancel': 'Cancel',
  'label.save': 'Save',
  'label.next': 'Next',

  // add-district-form
  'addDistrict.fieldError.name.required': 'School district name is required',
  'addDistrict.fieldError.name.maxLength': 'Maximum character allowed is 20',
  'addDistrict.fieldError.country.required': 'Country is required',
  'addDistrict.fieldError.state.required': 'State is required',
  'addDistrict.fieldError.intermediateUnits.required':
    'Intermediate unit is required',
  'addDistrict.addDistrictName': 'School District Name',
  'addDistrict.addCountryName': 'Select Country',
  'addDistrict.addStateName': 'Select State',
  'addDistrict.addInterUnitName': 'Select Intermediate Unit',
  'addDistrict.addDistrictName.placeholder': 'Enter district name',
  'addDistrict.addCountryName.placeholder': 'Select Country',
  'addDistrict.addStateName.placeholder': 'Select State',
  'addDistrict.addInterUnitName.placeholder': 'Select Intermediate Unit',
  'label.addDistrict': 'Save',
  'label.updateDistrict': 'Update District',
  'label.title.createDistrict': 'Add New School District',
  'label.title.disableDistrict': 'Disable District',
  'disableDistict.disableModal.text':
    'Are you sure you want to Deactivate the district {districtName}?',
  'success.districtCreated': 'School district created successfully',
  'success.districtUpdated': 'District details updated successfully',
  'success.templateDownloaded': 'Template downloaded successfully',
  'success.schoolImported': 'Successfully imported the data',
  'success.lessonDeleted': 'Module deleted successfully',
  'success.lessonSorted': 'Lesson sorted successfully',
  'label.disable': 'Disable',
  'label.select.school': 'Select School',
  //form data

  'label.school_name': 'School Name',
  'label.enter_school_name': 'Enter school name',
  'label.profile_image': 'Profile Image (Maximum Image size is 2 MB)',
  'label.website_url': 'Website URL',
  'label.enter_website_url': 'Enter website URL',
  'label.enter_country': 'Enter country',
  'label.country': 'Country',
  'label.enter_state': 'Enter state',
  'label.state': 'State',
  'label.enter_intermediate_unite': 'Enter intermediate Unit',
  'label.intermediate_unit': 'Intermediate Unit',
  'label.school_district': 'School District',
  'label.enter_school_district': 'Enter school district',
  'label.full_address': 'Full Address',
  'label.enter_full_address': 'Enter full address',
  'label.zip_code': 'Zip Code',
  'label.enter_zip_code': 'Enter zip code',
  'label.enter_contact_form_name': 'Enter contact first name',
  'label.contact_first_name': 'Contact First Name',
  'label.contact_last_name': 'Contact Last Name',
  'label.enter_contact_last_name': 'Enter contact last name',
  'label.email_address': 'Email Address',
  'label.enter_email_address': 'Enter email address',
  'label.select_designation': 'Select designation',
  'label.designation': 'Designation',
  'label.enter_contact_number': 'Enter contact number',
  'label.contact_number': 'Contact No.',
  'label.alternate_contact_number': 'Alternate Contact No.',
  'label.first_name': 'First Name',
  'label.last_name': 'Last Name',
  'label.enter_first_name': 'Enter First Name',
  'label.enter_last_name': 'Enter Last Name',
  'label.email_address_parents': 'Email Address of Parents',
  'label.create_new_password': 'Create New Password',

  'label.license_start_date': 'License Start Date',
  'label.license_start_subtitle':
    'Specify Commencement Date for School Licensing.',
  'label.new_content_announcements': 'New Content Announcements',
  'label.new_content_subtitle':
    'Get all the latest updates on all new content releases and information.',
  'label.pilot_program': 'Pilot Program',
  'label.pilot_program_description':
    'Specify if the school is a pilot program designed to evaluate the effectiveness of a new educational method, curriculum, or technology.',
  'label.task_reminder': 'Task Reminder',
  'label.task_reminder_subtitle': 'Display 10 minutes timer for aligned task.',
  'label.weekly_progress': 'Weekly Progress Summary',
  'label.weekly_progress_subtitle':
    'Keep track of your modules progress and receive an informative weekly report.',
  'label.notification_settings': 'Notification Settings',
  'label.contact_details_school': 'Contact Details of School',
  'label.student_name': 'Student Name',
  'label.student_email_address': 'Student Email Address',
  'label.assigned_counsellor': 'Assigned Counselor',
  'label.assigned_teacher': 'Assigned Teacher',
  'label.token_earned': 'Tokens Earned',
  'label.quick_actions': 'Quick Actions',
  'label.add_student': 'Add Student',
  'label.addStaff': 'Add Staff',

  'label.first_name_required': 'First Name is required',
  'label.last_name_required': 'Last Name is required',
  'label.email_required': 'Email is required',
  'label.password_required': 'Password is required',
  'label.confirm_password_required': ' Confirm Password is required',
  'label.password_not_matched': 'Password doesnt match',
  'label.counsellor_required': 'Counselor is required',
  'label.teacher_required': 'Teacher is required',
  'label.enter_usernames': 'Enter username',
  'label.confirm_password': 'Confirm Password',
  'label.re-enter_password': 'Re-enter new password',
  'label.assign_counselors': 'Assign Counselor',
  'label.select_counsellor': 'Select Counselor',
  'label.assign_teachers': 'Assign Teacher',
  'label.select_teachers': 'Select Teacher',

  'label.fieldError.first_name.maxLength': 'Maximum character allowed is 150',
  'label.fieldError.email': 'Enter valid email address',
  'label.save_changes': 'Save Changes',
  'label.edit': 'Edit',
  'label.update_success_message':
    'Congratulations, the student account has been updated',
  'label.create_success_message':
    'Congratulations, the student account has been created',

  'label.idNumber': 'ID Number',
  'label.staffMemberName': 'Staff Members Name',
  'label.staffEmailAddress': 'Staff Email Address',
  'label.classesAssigned': 'Classes Assigned',
  'label.noStudents': 'No. of Students',
  'label.counsellors': 'Counselors',
  'label.teachers': 'Teachers',
  'label.viewAll': 'View All',
  'success.updated': 'Success updated successfully',
  'student.success.updated': 'Student account has been updated successfully',

  // Error : Something went wrong
  'error.somethingWentWrong': 'Something went wrong',

  'info.errorReport': 'Error Report Downloaded',

  'step.downloadTemplate': 'Step1: Download Template',
  'step.uploadTemplate': 'Step2: Upload Item Template',

  'label.add_student_heading': 'Add New Student',
  'label.registration_instructions':
    'Fill out the form carefully for registration',

  'label.dropFile.text': 'Drop the Excel file here ...',
  'label.dragFile.text':
    "Drag 'n' drop an Excel file here, or click to select file",

  'label.add_student_heading': 'Add New Student',
  'label.registration_instructions':
    'Fill out the form carefully for registration',
  'label.deactivate': 'Deactivate',
  'label.deactivate-student-profile':
    'Are you sure you want to deactivate Student account ?',
  'success.schoolDeactivated': 'School have deactivated successfully',
  'label.file_size_error': 'Check your file size',
  'label.password_changed_success': 'Password changed successfully',

  'login.success': 'Login Successfully',
  'token.required': 'The token field is required',
  'user.notExist': 'User doesnot exist',
  'token.mismatch': 'Token does not matched',
  'user.invalid': 'Invalid user',
  'token.expired': 'The token is expired',
  'label.add_student_heading': 'Add New Student',
  'label.registration_instructions':
    'Fill out the form carefully for registration',

  'label.dropFile.text': 'Drop the Excel file here ...',
  'label.dragFile.text':
    "Drag 'n' drop an Excel file here, or click to select file",
  'label.deactivate': 'Deactivate',
  'label.activate': 'Activate',
  'success.schoolDeactivated': 'School have deactivated successfully',
  'label.password_changed_success': 'Password changed successfully',
  'label.id_number': 'ID Number',
  'label.enter_id_number': 'Enter ID number',
  'label.select_grade': 'Select Grade',
  'label.assign_group': 'Assign Group',
  'label.add_teacher_counsellor': 'Add New Teacher/ Counselor/ School Admin',
  'label.group_required': 'Group is required',
  'label.grade_required': 'Grade is required',
  'label.id_required': 'Id is required',
  'label.designation_required': 'Role is required',
  'label.file_size_error': 'Choose correct File size',
  'label.staff_added_success':
    'Congratulations, the Staff Account has been created',
  'success.student_updated': 'Student Updated Successfully',
  'success.student_added': 'Student Added Successfully',

  'school.statusModal.deactivate':
    'Are you sure you want to deactivate this school ?',
  'school.statusModal.activate':
    'Are you sure you want to activate this school ?',
  'label.activate-student-profile':
    'Are you sure you want to activate this student account ?',
  'label.deactivate-student-profile':
    'Are you sure you want to deactivate this student account ?',
  'staff.statusModal.activate':
    'Are you sure you want to activate this {staffMember} ?',
  'staff.statusModal.deactivate':
    'Are you sure you want to deactivate this {staffMember} ?',
  'superAdmin.statusModal.activate': 'Are you sure you want to activate ?',
  'superAdmin.statusModal.deactivate': 'Are you sure you want to deactivate?',
  'pdf.statusModal.activate': 'Are you sure you want to activate this pdf ?',
  'pdf.statusModal.deactivate':
    'Are you sure you want to deactivate this pdf ?',
  'lesson.statusModal.enable': 'Are you sure you want to enable this module ?',
  'lesson.statusModal.disable':
    'Are you sure you want to disable this module ?',

  'label.add_student_heading': 'Add New Student',
  'label.registration_instructions':
    'Fill out the form carefully for registration',
  'label.deactivate': 'Deactivate',
  'label.deactivate-student-profile':
    'Are you sure you want to deactivate Student account ?',
  'success.schoolDeactivated': 'School have deactivated successfully',
  'label.file_size_error': 'Check your file size',
  'label.password_changed_success': 'Password changed successfully',
  'label.add_student_heading': 'Add New Student',
  'label.registration_instructions':
    'Fill out the form carefully for registration',
  'label.fieldError.school-name.required': 'School name is required.',
  'label.fieldError.website-url.required': 'Website URL is required.',
  'label.fieldError.country.required': 'Country is required.',
  'label.fieldError.state.required': 'State is required.',
  'label.fieldError.intermediate.required': 'Intermediate is required.',
  'label.fieldError.district.required': 'District is required.',
  'label.fieldError.address.required': 'Address is required.',
  'label.fieldError.zip-code.required': 'Zip code is required.',
  'label.fieldError.first-name.required': 'First Name is required.',
  'label.fieldError.last-name.required': 'Last Name is required.',
  'label.fieldError.email.required': 'Email is required.',
  'label.fieldError.designation.required': 'Designation is required.',
  'label.fieldError.phone-number.required': 'Phone number is required.',
  'label.email_exists': 'User with this email already exists',
  'success.school_updated': 'School has successfully Updated',
  'success.added_success': 'School has successfully Added',

  'label.fieldError.whitespace': 'Please enter Value',
  'label.fieldError.password':
    'Password must contain at least one lowercase letter, one uppercase letter, one digit, one special character, and be at least 8 characters long",',
  'label.email_exists': 'User email already exists',
  'link.expired':
    'Link is expired or invalid! If you forgot your password use forgot password',
  'label.details_of_islands': 'All Islands details',
  'label.manage-contents': 'Manage Contents',
  'label.manage-content': 'Manage Content',
  'label.editLessonStyle': 'Edit Modules Style',
  'label.sectionsCount': 'No. of Sections',
  'label.seeSections': 'See Sections',
  'label.editDetails': 'Edit Details',
  'label.disable': 'Disable',
  'label.enable': 'Enable',
  'label.helpDetails': 'Help details',
  'editIsland.fieldError.name.required': 'Island name is required',
  'editIsland.fieldError.name.maxLength': 'Maximum character allowed is 20',
  'editIsland.fieldError.name.minLength': 'Minimum character allowed is 2',
  'editIsland.fieldError.desc.required': 'Island description is required',
  'editIsland.fieldError.desc.maxLength': 'Maximum character allowed is 200',
  'editIsland.fieldError.desc.minLength': 'Minimum character allowed is 10',
  'editIsland.editIslandName': 'Island Name',
  'editIsland.editIslandName.placeholder': 'Enter island name',
  'editIsland.editIslandDesc': 'Island Description',
  'editIsland.editIslandDesc.placeholder': 'Enter island description',
  'label.save': 'Save',
  'success.islandUpdated': 'Island details updated successfully',
  'label.uploadImage': 'Image:',
  'label.uploadFile': 'Upload File',
  'label.title.islandDetails': 'Island Details',
  'label.text.uploadedImage': 'Uploaded image: ',
  'island.statusModal.enable': 'Are you sure you want to enable this island ?',
  'island.statusModal.disable':
    'Are you sure you want to disable this island ?',
  'label.maxFileSize': 'Maximum file size supported is 512MB.',
  'addDistrict.fieldError.name.minLength': 'Minimum character allowed is 2',
  'addDistrict.fieldError.name.invalid': 'District name is invalid',
  'label-all-section-details': 'All Section details',
  'label.add_new_section': 'Add New section',
  'label.sections_name': 'Sections Name',
  'label.status': 'Status',
  'label.created': 'Created On',
  'label.last_edited': 'Last Edited On',
  'label.s_no': 'S no.',
  'label.edit_details': 'Edit Details',
  'label.delete_module': 'Delete',
  'label.section_name': 'Section Name',
  'label.enter_section_name': 'Enter Section Name',
  'label.token_number': 'Number of Tokens',
  'label.enter_token_number': 'Enter number of tokens',
  'label.object': 'Object',
  'label-section-details': 'Section details',
  'label.select_object': 'Select Object',
  'label.section_name_required': 'Section name is required',
  'label.token_required': 'Token is required',
  'label.object_required': 'Object is required',
  'label.module_element_name_required': 'Module Element Name is required',
  'label.license_expiry_date': 'License expiry date',
  'label.lessonsName': 'Module Name',
  'label.addNewLesson': 'Add New Module',
  'label.lessonDetails': '{sectionName} Module Details',
  'label.editModule': 'Edit Module',
  'label.draft': 'Draft',
  'label.published': 'Published',
  'label.publish': 'Publish',
  'label.fieldError.empty': 'Filed cannot be empty',
  'label.fieldError.minLength': 'Please enter minimum 5 char',
  'label.textStyles': 'Text Styles',
  'label.background': 'Background Color',
  'lessonStyle.title.placeholder': 'Enter title',
  'lessonStyle.title': 'Title',
  'label.hex': 'Hex:',
  'success.updated.lessonStyle': 'Module style updated successfully',
  'label.fieldError.invalid.id': 'Id number is invalid',
  'label.fieldError.id.minLength': 'Minimum allowed digits is 2',
  'label.fieldError.id.maxLength': 'Maximum allowed digits is 10',
  'label.title.lessonDetails': 'Module Details',
  'lessonDetails.lessonName': 'Module Name',
  'lessonDetails.lessonName.placeholder': 'Enter module name',
  'lessonDetails.lessonShortDesc': 'Subtext',
  'lessonDetails.lessonShortDesc.placeholder': 'Enter module subtext',
  'lessonDetails.lessonMinutes': 'Duration',
  'lessonDetails.lessonMinutes.placeholder': 'Enter module duration in minutes',
  'lessonDetails.lessonSeconds.placeholder': 'Enter module duration in seconds',
  'lessonDetails.fieldError.name.required': 'Module name is required',
  'lessonDetails.fieldError.name.minLength': 'Minimum character allowed is 2',
  'lessonDetails.fieldError.name.maxLength': 'Maximum character allowed is 80',
  'lessonDetails.fieldError.desc.required':
    'Module short description is required',
  'lessonDetails.fieldError.desc.minLength': 'Minimum character allowed is 10',
  'lessonDetails.fieldError.desc.maxLength': 'Maximum character allowed is 200',
  'lessonDetails.fieldError.readTime.required': 'Module duration is required',
  'lessonDetails.fieldError.readTime.minValue':
    'Minimum read time duration is 1 minute',
  'success.lessonDetailsUpdated': 'Module details updated successfully',
  'lessonDetails.fieldError.readTime.maxValue':
    'Maximum read time duration is 200 minutes',
  'label.saveChanges': 'Save Changes',
  'label.selectIntermediateUnit': 'Select Intermediate Unit',
  'label.selectdistricts': 'Select Districts',
  'label.selectGroup': 'Select Group',
  'label.selectGrade': 'Select Grade',
  'label.student_welcome_text':
    'Welcome to GradRock, your personalized learning hub!',
  'label.student_welcome_text2': 'We are delighted to have you here.',
  'label.starting_up': 'Starting Up...',
  'label.choose_avatar': 'Choose your Vehicle',
  'label.continue': 'Continue',
  'label.start_lesson': 'START A MODULE',
  'lessonDetails.fieldError.readTime.typeError': 'Module duration is invalid',
  'label.startBuildingContent': 'Start building content:',
  'label.content.text': 'Text',
  'label.content.attachment': 'Attachment',
  'label.content.image': 'Image/Video',
  'label.content.articles': 'Articles',
  'label.content.alert': 'Alerts',
  'label.content.note': 'Note',
  'label.content.remember': 'Remember',
  'label.content.bestAdvice': 'Best Advice',
  'label.attachment': 'Attachment',
  'label.maxFileSize': '(Maximum File size is 512MB)',
  'label.attach.imageVideo': 'Add Image/Video',
  'label.text.addContent': 'Add Content',
  'label.text.addText': 'Add Text Type',
  'label.saveAsDraft': 'Save as Draft',
  'label.title.articleDetails': 'Article Details',
  'label.text.articleTitle': 'Article Title',
  'label.text.articleTitle.placeholder': 'Enter article title',
  'label.text.articleLink': 'Article Link',
  'label.text.articleLink.placeholder': 'Enter article link',
  'label.addContent': 'Add Content',
  'label.articleDetails': 'Article Details',
  'addArticle.fieldError.title.required': 'Article title is required',
  'addArticle.fieldError.title.invalid': 'Article title is invalid',
  'addArticle.fieldError.link.required': 'Article link is required',
  'addArticle.fieldError.title.minLength': 'Minimum character allowed is 2',
  'addArticle.fieldError.title.maxLength': 'Maximum character allowed is 80',
  'success.newLessonCreated': 'New Module Created Successfully',
  'success.lessonContentUpdated': 'Module content updated successfully',
  'label.uploadedFileName': 'Uploaded file name: ',
  'lessonContent.text.required': 'Text is required',
  'lessonContent.file.required': 'File is required',
  'lessonStyle.fieldError.name.required': 'Title is required',
  'lessonStyle.fieldError.image.required': 'Image is required',
  'label.lessonStyles': 'Module Styles',
  'label.uploadImage': 'Upload Image',
  field_required: 'Field is required',
  'error.districtAlreadyExist': 'District name already exist',
  'label.viewPreview': 'View a Preview',
  'label.generateTextToSpeech': 'Generate Text to Speech',
  'label.publishContent': 'Publish Content',
  'label.username_exists': 'Username already exists',
  'error.districtAlreadyExist': 'District name already exist',
  'info.noLessonContent': 'Please add some content in the module',
  'info.noNewLessonContent': 'You have not added any new content',
  'label.fieldError.invalid.username': 'Username is invalid',
  'label.fieldError.invalid.firstName': 'First name is invalid',
  'label.fieldError.invalid.lastName': 'Last name is invalid',
  'addArticle.fieldError.link.invalid': 'Article link is invalid',
  'addArticle.fieldError.image.required': 'Article image is required',
  'label.islandsContent': 'Islands Content',
  'label.islandsContent': 'Islands Content',
  'label.noIslandDetailsChanged': 'No Island Details have been changed',
  'label.enableSection': 'Are you sure you want to enable this section?',
  'label.disableSection': 'Are you sure you want to disable this section?',
  'island.success.updated': 'Island status has been updated successfully',
  'section.success.updated': 'Section status has been updated successfully',
  'module.success.updated': 'Module status has been updated successfully',
  'label.readArticleText': 'Read the article given below for more information.',
  'label.mins': 'mins',
  'label.seconds': 'sec',
  'label.placeholder.seconds': 'Seconds',
  'exit.Lesson': 'Exit Module',
  'keep.Learning': 'Keep Learning',
  'hold.rightThere.text': 'Hold it right there!',
  'loose.progress.text':
    ' You are doing wonderfully. If you quit now, you’ll lose all your progress ...',
  'label.tooltip.continue_with_island': '{islandName} Island',
  'label.dropFiles': 'Drop the files here ...',
  'label.clickFile': 'Click to select file',
  'noVideoSupport.browser': 'Your browser does not support the video tag.',
  'selectFile.LessThan2Mb': 'Please select file less than 2MB',
  'label.allLessonCompleted':
    'You have reached the end of this section. Great job on completing all the modules!',
  'lesson.complete': 'Module complete',
  'reached.goal': 'Nice job reaching your goal!',
  'label.back': 'Back',
  'label.next-lesson': 'Next Module',
  'label.previous-lesson': 'Previous Module',
  'label.exit-lesson': 'Exit Module',
  startNewLesson: 'Start new module',
  'label.timeTaken': 'Time taken',
  'selectFile.LessThan2Mb': 'Please select file less than 2MB',
  'label.no-objects-left': 'No Objects are left to select',
  'label.discard': 'Discard',
  'lessonDetails.fieldError.desc.invalid': 'Module subtext is invalid',
  'lessonDetails.fieldError.name.invalid': 'Module name is invalid',
  'label.noModuleDetailsChanged': 'No module details has been changed',
  'error.invalidFile.type':
    'Invalid file type. Please upload only JPEG, PNG, MP4, or WEBM files.',
  'label.invalidAttachmentFileType':
    'Invalid file type. Allowed types are JPEG, PNG, DOCX, XLSX, PDF, and PPT.',
  'label.all_islands': 'All Islands',
  'label.bookmarks': 'Bookmarks',
  'label.leaderboards': 'Leaderboards',
  'label.dashboard': 'Dashboard',
  'label.settings': 'Settings',
  'label.profile': 'Profile',
  'label.sure_to_logout': 'Are you sure you want to Log out?',
  'success.lessonBookmark': 'The module has been Bookmarked successfully',
  'success.contentBookmark': 'The content has been Bookmarked successfully',
  'success.download.fileAttachment': 'File attachment downloaded successfully',
  'success.lessonUnBookmark':
    '"The module has been successfully unbookmarked."',
  'success.contentUnBookmark': 'The content has been successfully unbookmarked',
  'label.objects': 'Objects',
  password_set_success: 'Password has been set successfully',
  'label.email_verified_success': 'Email verified successfully',
  'label.tabs.QuesAns': 'Question & Answers',
  'label.tabs.notes': 'Notes',
  'label.tabs.notify': 'Notifications',
  'label.tabs.learnTools': 'Learning Tools',
  'add.newQuestion': 'Add New Question',
  'label.pinnedBy': 'Pinned by: ',
  'label.submitQuestion': 'Submit Question',
  'success.questionDeleted': 'The question has been deleted successfully',
  'success.questionAdded': 'The question has been added successfully',
  'addQuestion.fieldError.name.required': 'Question title is required',
  'addQuestion.fieldError.name.minLength': 'Minimum character allowed is 2',
  'addQuestion.fieldError.name.maxLength': 'Maximum character allowed is 120',
  'addQuestion.fieldError.name.invalid': 'Question title is invalid',
  'label.writeQuesText': 'Write a question',
  'label.wordsLimit': '120 words limit',
  'addQuestion.field.placeholder': 'Enter question title',
  'label.country-name': 'Country Name',
  'label.help': 'Help',
  'label.add_help': 'Add Help',
  'label.pdf': 'PDF',
  'label.title': 'Title',
  'label.upload_pdf': 'Upload PDF',
  'label.onlyPdfAllowed': 'Only PDF can be uploaded',
  'label.pdf_required': 'PDF upload is required',
  'label.title_required': 'Title is required',
  'label.enter_pdf_title': 'Enter PDF Title',
  'success.pdf_added_success': 'PDF has been successfully added',
  'success.pdf_status_updated': 'PDF status has been updated successfully',
  'error.unsupportedFileType':
    'Invalid file type. Please upload .xlsx or .csv files only.',
  'label.city': 'City',
  'label.enter_city_name': 'Enter city name',
  'label.assigned_school': 'Assigned school',
  'label.view_profile': 'View profile',
  'label.admin_first_name': 'Admin First Name',
  'label.admin_last_name': 'Admin Last Name',
  'label.enter_admin_first_name': 'Enter admin first name',
  'label.enter_admin_last_name': 'Enter admin last name',
  'label.admin_email_address': 'Admin Email Address',
  'label.enter_admin_email_address': 'Enter admin email address',
  'label.select_admin_designation': 'Select admin designation',
  'label.admin_designation': 'Admin Designation',
  'label.enter_admin_number': 'Enter admin contact number',
  'label.admin_contact_number': 'Admin Contact No.',
  'label.admin_alternate_contact_number': 'Admin Alternate Contact No.',
  'label.enter_admin_alternate_number': 'Enter admin alternate contact no.',
  'label.fieldError.invalid.title': 'Enter letters and numbers only',
  'placeholder.searchQues': 'Search all question',
  'label.search': 'Search',
  'label.ansNotAvail': 'Answer not available',
  'lesson.featureQuestion': 'Features question in this lesson',
  'lesson.quesAskByYou': 'Question Asked by you',
  'label.module-contents': 'Module Contents',
  'label.askedBy': 'Asked by: ',
  'label.writeAns': 'Write answer',
  'label.questionAskStudents': 'Questions asked by students',
  'label.submitAnswer': 'Submit Answer',
  'label.backToQuestions': 'Back to all question',
  'label.charactersLeft': '{count} characters left',
  'label.tabs.Announcements': 'Announcements',
  'success.quesPinned': 'Question has been pinned successfully',
  'success.quesUnpinned': 'Question has been unpinned successfully',
  'success.answerSubmitted': 'Answer has been submitted successfully',
  'label.token_max_value': 'Token value cannot be greater than 100',
  'pinnedBy.anotherUser':
    'Unpin action blocked. Another staff member has already pinned this question.',
  'success.answerUpdated': 'Answer updated successfully',
  'addAnswer.fieldError.answer.required': 'Answer is required',
  'addAnswer.fieldError.answer.minLength': 'Minimum character allowed is 2',
  'label.token_max_value': 'Token value cannot be greater than 100',
  'deleteModal.quesText': 'Are you sure you want to delete this question ?',
  'pin.quesText': 'Are you sure you want to {pinStatus} this question ?',
  'label.confirm': 'Confirm',
  'label.title.confirmDelete': 'Confirm Delete',
  'label.manage-questions': 'Manage Questions',
  'label.provideAnswer': 'Provide Answer',
  'label.question': 'Question',
  'label.questionDate': 'Question Date',
  'label.moduleName': 'Module Name',
  'label.details_of_questions': 'Question Details',
  'label.answer': 'Answer',
  'label.searchByQuesAndAns': 'Search by question or answer',
  'label.askBy': 'Asked by',
  'label.askOn': 'Asked on',
  'label.pinBy': 'Pinned by',
  'label.ansBy': 'Answered by',
  'label.ansOn': 'Answered on',
  'error.requiredAns': 'Answer is required',
  'label.token_max_value': 'Token value cannot be greater than 100',
  'label.role': 'Role',
  'label.select_role': 'Select role',
  'label.fieldError.phone_number': 'Enter valid phone number',
  'staff.added': 'Staff Member added successfully',
  'label.fieldError.phone_number': 'Enter valid phone number',
  'label.profile_updated': 'Profile updated successfully',
  'label.change-password': 'Change Password',
  'label.today': 'Today',
  'label.earlier': 'Earlier',
  'label.recentNotify': 'Recent  Notifications',
  'label.progressStatus': 'Progress Status',
  'label.studentDetails': 'Student Details',
  'label.section.detailsStudent': 'Student’s Details',
  'heading.studentDashboard': 'Student Dashboard',
  'label.teacher': 'Teacher',
  'label.counsellor': 'Counselor',
  'label.my_profile': 'My Profile',
  'label.students': 'Students',
  'label.searchByName': 'Search by name',
  'label.tokensEarned': 'Tokens Earned',
  'label.completedModules': 'Modules Completed',
  'label.completedSections': 'Sections Completed',
  'label.islandsCompleted': 'Islands Completed',
  'label.myProfile': 'My Profile',
  'label.weekly_time': 'Weekly Time Spent',
  'label.weekly_performance': 'Weekly Performance',
  'label.ongoing_islands': 'Ongoing Islands',
  'label.module_statistics': 'Module Statistics',
  'label.sections_covered': 'Sections Covered',
  'label.chapters_covered': 'Chapters Covered',
  'label.go_to_lesson': 'Go to lesson',
  'label.noStaff': 'No. of Staffs',
  'label.heading.schoolDistricts': 'School Districts',
  'label.heading.schools': 'Schools',
  'label.heading.staffs': 'Staffs',
  'label.heading.students': 'Students',
  'label.today': 'Today',
  'label.earlier': 'Earlier',
  'label.recentNotify': 'Recent  Notifications',
  'label.noSchool': 'No. of Schools',
  'label.schoolDistrictName': 'School District Name',
  'label.myProfile': 'My Profile',
  'label.add_announcement': 'Add Announcement',
  'label.enter_announcement': 'Enter Announcement',
  'label.announcement': 'Announcement',
  'label.success_announcement': 'Announcement added successfully',
  'label.description': 'Description',
  'label.view-contents': 'View Content',
  'label.required_announcement': 'Announcement field is required',
  'label.tabs.notifyAndAnnouncements': 'Notifications & Announcements',
  'label.recentNotifyAndAnnouncements': 'Recent  Notifications/Announcements',
  'label.subHead.notify': 'Notifications',
  'label.super_admin_management': 'Super Admin Management',
  'label.edit-profile': 'Edit Profile',
  'label.super_admin_name': 'Super Admin Name',
  'label.details_of_admin': 'Details of Super Admins',
  'label.addSuperAdmin': 'Add Super Admin',
  'success.super_admin_added_success': 'Super Admin Added Successfully',
  'success.super_admin_updated': 'Super Admin Updated Successfully',
  'module.super_admin_status.updated':
    'Super Admin status has been updated successfully',
  'label.reports': 'Reports',
  'label.pilot_report': 'Pilot Program Report',
  'label.pilot_reporting': 'Pilot Reporting',
  'label.download_pilot_report': 'Download Pilot Report',
  'label.student_progress_report': 'Student Progress Report',
  'label.exportReport': 'Export Report',
  'label.coins_earned': 'Coins',
  'label.fantasy_module_count': 'Fantasy',
  'label.rural_module_count': 'Rural',
  'label.tropical_module_count': 'Tropical',
  'label.urban_module_count': 'Urban',
  'label.total_modules_completed': 'Completed',
  'placeholder.add_notes_here': 'Add your notes here...',

  'addNotes.fieldError.notes.required': 'Notes is required',
  'addNotes.fieldError.notes.minLength': 'Minimum character allowed is 2',
  'addNotes.fieldError.notes.maxLength': 'Maximum character allowed is 500',
  'success.noteAdded': 'Note has been added successfully',
  'success.noteUpdated': 'Note has been updated successfully',
  'success.noteDeleted': 'Note has been deleted successfully',
  'label.notesLimit': '500 words limit',
  'label.tabs.attachments': 'Attachments',
  'label.button.update': 'Update',
  'label.uploadAttachment': 'Upload Attachment',
  'label.attachmentsUploadedByStudents': 'Attachments uploaded by students',
  'label.uploadedBy': 'Uploaded by',
  'label.noUploadedFiles': 'No uploaded files',
  'label.student-attachments': 'Student Attachments',
  'label.view-student-attachments': 'View Attachments',
  'label.list-of-attachments': 'List of Student Attachments',
  'label.module': 'Module',
  'label.select.module': 'Select Module',
  'label.filterByLesson': 'Filter by Module',
  'label.allLessons': 'All Modules',
  'label.firstLessonReached': 'You have reached the first module',

  'label.limits_settings': 'Limits Settings',
  'label.limits_settings_subtitle':
    'Set a maximum limit on the number of users permitted per license.',
  'label.students_limit': 'Students Limit',
  'label.enter_students_limit': 'Enter Students Limit',
  'label.school_admins_limit': 'School Admins Limit',
  'label.enter_school_admins_limit': 'Enter School Admins Limit',
  'label.teachers_limit': 'Teachers Limit',
  'label.enter_teachers_limit': 'Enter Teachers Limit',
  'label.counsellors_limit': 'Counselors Limit',
  'label.enter_counsellors_limit': 'Enter Counselors Limit',
  'label.fieldError.minValue': 'Minimum value allowed is 1',
};

export default locale;
