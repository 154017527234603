import * as Yup from 'yup';

export const addSectionFieldName = {
  NAME: 'name',
  TOKEN: 'token_credits',
  OBJECT: 'module_element',
  MODULE_ELEMENT_NAME: 'module_element_name',
};

export const addSectionInitialValues = {
  [addSectionFieldName.NAME]: '',
  [addSectionFieldName.TOKEN]: '',
  [addSectionFieldName.OBJECT]: '',
  [addSectionFieldName.MODULE_ELEMENT_NAME]: '',
};

export const validationSchema = intl => {
  return Yup.object().shape({
    [addSectionFieldName.NAME]: Yup.string().required(
      intl.formatMessage({id: 'label.section_name_required'}),
    ),
    [addSectionFieldName.TOKEN]: Yup.string()
      .test('max', intl.formatMessage({id: 'label.token_max_value'}), value => {
        if (!value || isNaN(value)) {
          return true;
        }
        return parseFloat(value) <= 100;
      })
      .required(intl.formatMessage({id: 'label.token_required'})),
    [addSectionFieldName.OBJECT]: Yup.string().required(
      intl.formatMessage({id: 'label.object_required'}),
    ),
    [addSectionFieldName.MODULE_ELEMENT_NAME]: Yup.string().required(
      intl.formatMessage({id: 'label.module_element_name_required'}),
    ),
  });
};
